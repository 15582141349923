import retry from "async-retry";
import BigNumber from "bignumber.js";
import * as ethers from "ethers";

import config from "./config.json";
import lensContractABI from "./abis/Lens.json";
import addListeners from "./listeners";
import savaxABI from "./abis/Savax.json";

import "./scss/main.scss";

const rpcProvider = new ethers.providers.JsonRpcProvider(config.rpcUrl);

const lensContract = new ethers.Contract(
  config.lensContractAddress,
  lensContractABI,
  rpcProvider
);

const stakedAvax = new ethers.Contract(
  config.savaxContractAddress,
  savaxABI,
  rpcProvider
);

window.onload = () => {
  addListeners();

  const lendingMarketTvl = document.getElementById("lending-market-tvl");
  const liquidStakingTvl = document.getElementById("liquid-staking-tvl");
  const totalTvl = document.getElementById("total-tvl");

  fetchData()
    .then((data) => {
      lendingMarketTvl.textContent = "$" + data.lending.toFormat(2);
      lendingMarketTvl.style.visibility = "visible";

      liquidStakingTvl.textContent = "$" + data.staking.toFormat(2);
      liquidStakingTvl.style.visibility = "visible";

      totalTvl.textContent = "$" + data.staking.plus(data.lending).toFormat(2);
    })
    .catch(() => {});
};

async function fetchData() {
  const marketsMetadata = await retry(
    () => lensContract.getMarketMetadataForAllMarkets(),
    { retries: 3 }
  );

  const pooledAvax = await retry(() => stakedAvax.totalPooledAvax(), {
    retries: 3,
  });

  const avaxMarketData = marketsMetadata.find(
    (market) =>
      market.underlyingToken === "0x0000000000000000000000000000000000000000"
  );

  return {
    lending: calculateTotalSupplyUSDValue(marketsMetadata),
    staking: calculateStakingTVL(avaxMarketData, pooledAvax),
  };
}

function calculateTotalSupplyUSDValue(marketsMetadata) {
  return marketsMetadata
    .map(calculateMarketTVL)
    .reduce((accum, next) => accum.plus(next), new BigNumber(0));
}

function calculateStakingTVL(marketMetadata, pooledAvax) {
  const underlyingTokenDecimals = marketMetadata.underlyingTokenDecimals.toNumber();
  const totalUnderlyingSupply = new BigNumber(pooledAvax.toString()).shiftedBy(-underlyingTokenDecimals);
  const underlyingTokenPrice = calculateMarketUnderlyingTokenPrice(
    marketMetadata
  ).toFixed(underlyingTokenDecimals);

  return totalUnderlyingSupply.times(underlyingTokenPrice);
}

function calculateMarketTVL(marketMetadata) {
  const underlyingTokenDecimals = marketMetadata.underlyingTokenDecimals.toNumber();
  const totalUnderlyingSupply = new BigNumber(marketMetadata.totalUnderlyingSupply.toString()).shiftedBy(-underlyingTokenDecimals);
  const underlyingTokenPrice = calculateMarketUnderlyingTokenPrice(marketMetadata).toFixed(underlyingTokenDecimals);

  return totalUnderlyingSupply.times(underlyingTokenPrice);
}

function calculateMarketUnderlyingTokenPrice(marketMetadata) {
  return new BigNumber(marketMetadata.price.toString()).shiftedBy(
    -(36 - marketMetadata.underlyingTokenDecimals.toNumber())
  );
}
