import TypeIt from "typeit";

const addListeners = () => {
  const svg = document.querySelector(".chevron-down");

  function scrollToStatistics() {
    const element = document.getElementById("total-tvl");
    const offset = -200;
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: elementPosition + offset, behavior: "smooth" });
  }

  svg.addEventListener("click", function () {
    scrollToStatistics();
  });

  const appBtn = document.querySelector(".apps-btn");

  function scrolltoApps() {
    const element = document.querySelector(".card-container");
    const offset = -200;
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: elementPosition + offset, behavior: "smooth" });
  }

  appBtn.addEventListener("click", function () {
    scrolltoApps();
  });

  const sidebar = document.querySelector(".sidebar");
  const sidebarContainer = document.getElementById("sidebar-container");
  const toggleBtns = document.getElementsByClassName("toggle-sidebar");

  for (let toggleBtn of toggleBtns) {
    toggleBtn.addEventListener("click", function () {
      sidebar.classList.toggle("open");
      sidebarContainer.classList.toggle("open");
    });
  }

  sidebarContainer.addEventListener("click", function (e) {
    if (e.target === sidebarContainer) {
      closeSidebar();
    }
  });

  document.addEventListener("keyup", function (e) {
    if (e.key === "Escape") {
      closeSidebar();
    }
  });

  function closeSidebar() {
    sidebar.classList.remove("open");
    sidebarContainer.classList.remove("open");
  }

  function closeSidebarOnLargeScreens() {
    if (window.innerWidth > 768) {
      closeSidebar();
    }
  }

  window.addEventListener("resize", closeSidebarOnLargeScreens);

  document.addEventListener("scroll", function () {
    const element = document.getElementById("header");
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition === 0) {
      element.classList.remove("scrolling");
    } else {
      element.classList.add("scrolling");
    }
  });

  new TypeIt(".typewriter")
    .options({ speed: 300, loop: true })
    .type("STAKE")
    .pause(800)
    .delete(5)
    .type("SUPPLY")
    .pause(800)
    .delete(6)
    .type("BORROW")
    .pause(800)
    .delete(6)
    .type("BUILD")
    .go();
};

export default addListeners;
